import * as React from 'react';
import styled from 'styled-components/macro';

import { DetailParams, isDetailParamsWithFlights, MISSING_FLIGHT_INFO } from '../../lib';
import { trackGA4FormInteraction } from '../../tealium';
import RadioInput from '../RadioInput';
import {
  Cabin,
  CabinAndFlightPrice,
  isCabinWithFlightOnly,
  isCabinWithoutFlight,
  sortByAmount as byAmount,
} from '../CruiseList';
import SelectInput from '../SelectInput';
import { usePriceFormat } from '../PriceSwitch';
import { Option } from '../../types';
import {
  CabinPrice,
  isCabinWithoutInboundFlight,
  isCabinWithoutOutboundFlight,
} from '../CruiseList/Types';

const SELECT_WITH_FLIGHT = Symbol('SELECT_WITH_FLIGHT');
const SELECT_WITHOUT_FLIGHT = Symbol('SELECT_WITHOUT_FLIGHT');
const SELECT_FLIGHT = Symbol('SELECT_FLIGHT');

export type FlightSelectState = {
  hasNoFlightOption: boolean;
  hasNoInboundFlightOption: boolean;
  hasNoOutboundFlightOption: boolean;
  hasFlightOption: boolean;
  withFlight: boolean;
  flights: CabinAndFlightPrice[];
  selectedFlight: CabinAndFlightPrice | null;
};

type SelectWithFlightAction = {
  type: typeof SELECT_WITH_FLIGHT;
};
type SelectWithOutFlightAction = {
  type: typeof SELECT_WITHOUT_FLIGHT;
};
type SelectFlightAction = {
  type: typeof SELECT_FLIGHT;
  payload: string;
};

const SelectWrap = styled.div``;
const FlightWrap = styled.div`
  position: relative;
  height: 90px;

  label > span {
    position: absolute;
    bottom: 0;
  }
`;

type SearchActions =
  | SelectWithFlightAction
  | SelectWithOutFlightAction
  | SelectFlightAction;

function flightSelectReducer(state: FlightSelectState, action: SearchActions) {
  switch (action.type) {
    case SELECT_WITH_FLIGHT:
      return {
        ...state,
        withFlight: true,
      };
    case SELECT_WITHOUT_FLIGHT:
      return {
        ...state,
        withFlight: false,
      };
    case SELECT_FLIGHT:
      return {
        ...state,
        selectedFlight:
          state.flights.find(
            (flight) => toOptionCode(flight) === action.payload,
          ) || /* istanbul ignore next: unreachable */ null,
      };
  }
}

function useFlightSelectActions(dispatch: React.Dispatch<SearchActions>) {
  return React.useMemo(
    () => ({
      selectWithFlight: () => {
        dispatch({ type: SELECT_WITH_FLIGHT });
      },
      selectWithoutFlight: () => {
        dispatch({ type: SELECT_WITHOUT_FLIGHT });
      },
      selectFlight: (option: Option | null) => {
        if (!option) {
          return;
        }
        dispatch({ type: SELECT_FLIGHT, payload: option.code });
      },
    }),
    [dispatch],
  );
}

function byAmountAndName(key: 'arrivalAirport' | 'departureAirport') {
  return (a: CabinAndFlightPrice, b: CabinAndFlightPrice) => {
    const ba = byAmount(a, b);

    if (ba === 0) {
      const { name: directionA } = a[key];
      const { name: directionB } = b[key];

      if (directionA && directionB) {
        if (directionA > directionB) {
          return 1;
        }

        /* unreachable since airports with same name and price are filtered out before */
        /* istanbul ignore else */
        if (directionA < directionB) {
          return -1;
        }
        /* istanbul ignore next */
        return 0;
      } else if (directionA) {
        return 1;
      } else if (directionB) {
        return -1;
      } else {
        return 0;
      }
    }

    return ba;
  };
}

type FlightGroup = { [key: string]: CabinAndFlightPrice[] };

function createFlightGroupReducer(key: 'arrivalAirport' | 'departureAirport') {
  return (memo: FlightGroup, flight: CabinAndFlightPrice) => {
    const { name: directionAirportName } = flight[key];
    if (directionAirportName && !memo[directionAirportName]) {
      memo[directionAirportName] = [];
    }
    if (directionAirportName) {
      memo[directionAirportName].push(flight);
    }
    return memo;
  };
}

export function useFlightSelectReducer(
  cabin: Cabin,
  detailParams: DetailParams,
) {
  const initialState = React.useMemo<FlightSelectState>(() => {
    const flights = isCabinWithoutFlight(cabin) ? [] : cabin.pricesWithFlight;
    const selectedFlight =
      isCabinWithoutFlight(cabin) || !isDetailParamsWithFlights(detailParams)
        ? null
        : cabin.pricesWithFlight.find(
        ({ arrivalAirport, departureAirport }) =>
          arrivalAirport.code === detailParams.arrivalAirportCode &&
          departureAirport.code === detailParams.departureAirportCode,
      ) || cabin.pricesWithFlight[0];
    return {
      hasNoFlightOption: !isCabinWithFlightOnly(cabin),
      hasNoInboundFlightOption:
        !!selectedFlight && isCabinWithoutInboundFlight(selectedFlight),
      hasNoOutboundFlightOption:
        !!selectedFlight && isCabinWithoutOutboundFlight(selectedFlight),
      hasFlightOption: !isCabinWithoutFlight(cabin),
      withFlight: isCabinWithFlightOnly(cabin) ? true : Boolean(selectedFlight),
      flights,
      selectedFlight,
    };
  }, [cabin, detailParams]);

  return React.useReducer(flightSelectReducer, initialState);
}

type Props = {
  state: FlightSelectState;
  basePrice?: CabinPrice;
  dispatch: React.Dispatch<SearchActions>;
};

const PLACEHOLDER_OPTION = {
  code: '______',
  name: 'Bitte Auswählen',
};

function toOptionCode(flight: CabinAndFlightPrice): string {
  return `${flight.departureAirport.code || MISSING_FLIGHT_INFO}:${flight
    .arrivalAirport.code || MISSING_FLIGHT_INFO}`;
}

function createToOptionMapper(
  formatPrice: ReturnType<typeof usePriceFormat>,
  key: 'arrivalAirport' | 'departureAirport',
  diff?: CabinAndFlightPrice | CabinPrice,
) {
  return (flight: CabinAndFlightPrice): Option => {
    const showDiff = diff && flight.amount !== diff.amount;
    const { name: directionAirportName } = flight[key];

    return {
      name:
        (directionAirportName &&
          `${directionAirportName}${
            showDiff ? ` (${formatPrice(flight, diff)})` : ''
          }`) ||
        MISSING_FLIGHT_INFO,
      code: toOptionCode(flight),
    };
  };
}

// TODO: this is way too complex, what is happening here?!
function useAirportOptions(
  flights: CabinAndFlightPrice[],
  selectedFlight: CabinAndFlightPrice | null,
  basePrice: CabinPrice | undefined,
  key: 'arrivalAirport' | 'departureAirport',
): [Option[], Option] {
  const formatPrice = usePriceFormat();
  const oppositeKey =
    key === 'arrivalAirport'
      ? ('departureAirport' as const)
      : ('arrivalAirport' as const);
  const airportOptions = React.useMemo(() => {
    const { options, rest } = flights.reduce(
      (memo, flight) => {
        if (
          !selectedFlight ||
          flight[oppositeKey].name === selectedFlight[oppositeKey].name
        ) {
          memo.options.push(flight);
        } else {
          memo.rest.push(flight);
        }

        return memo;
      },
      { options: [], rest: [] } as {
        options: CabinAndFlightPrice[];
        rest: CabinAndFlightPrice[];
      },
    );

    const airportNames = options.map((value) => {
      return value[key].name || MISSING_FLIGHT_INFO;
    });

    const toOption = createToOptionMapper(
      formatPrice,
      key,
      selectedFlight || basePrice,
    );

    const toFlightGroups = createFlightGroupReducer(key);

    const others = Object.values(
      rest
        .filter((value) => {
          return !airportNames.includes(value[key].name || MISSING_FLIGHT_INFO);
        })
        .reduce(toFlightGroups, {}),
    )
      .map((flights) => flights.sort(byAmount)[0])
      .sort(byAmountAndName(key))
      .map(toOption);

    const sortedOptions = Object.values(options.reduce(toFlightGroups, {}))
      .map((flights) => flights.sort(byAmount)[0])
      .sort(byAmountAndName(key))
      .map(toOption);

    if (sortedOptions.length) {
      sortedOptions[sortedOptions.length - 1] = {
        ...sortedOptions[sortedOptions.length - 1],
        separate: true,
      };
    }

    return sortedOptions.concat(others);
  }, [flights, selectedFlight, formatPrice, key, oppositeKey, basePrice]);
  const selectedFlightCode = selectedFlight && toOptionCode(selectedFlight);
  const selected = React.useMemo(
    () => airportOptions.find(({ code }) => code === selectedFlightCode),
    [selectedFlightCode, airportOptions],
  );

  return [airportOptions, selected || PLACEHOLDER_OPTION];
}

const FlightSelect: React.FC<Props> = ({
                                         state: {
                                           withFlight,
                                           hasNoFlightOption,
                                           hasFlightOption,
                                           hasNoInboundFlightOption,
                                           hasNoOutboundFlightOption,
                                           selectedFlight,
                                           flights,
                                         },
                                         basePrice,
                                         dispatch,
                                       }) => {
  const firstInput = React.useRef<HTMLInputElement | null>(null);
  const {
    selectWithFlight,
    selectWithoutFlight,
    selectFlight,
  } = useFlightSelectActions(dispatch);
  const [departureAirportOptions, selectedDeparture] = useAirportOptions(
    flights,
    selectedFlight,
    basePrice,
    'departureAirport',
  );
  const [arrivalAirportOptions, selectedArrival] = useAirportOptions(
    flights,
    selectedFlight,
    basePrice,
    'arrivalAirport',
  );

  React.useEffect(() => {
    firstInput.current && firstInput.current.focus();
  }, []);

  return (
    <>
      <SelectWrap>
        <RadioInput
          onChange={() => {
            if (!withFlight) {
              trackGA4FormInteraction('cruie-details', 'click.radio-button.flight.with');

            }
            selectWithFlight();
          }}
          checked={withFlight}
          disabled={!hasFlightOption}
          innerRef={hasFlightOption ? firstInput : undefined}
        >
          Mit Flug
        </RadioInput>
        <RadioInput
          onChange={() => {
            if (withFlight) {
              trackGA4FormInteraction('cruie-details', 'click.radio-button.flight.without');
            }
            selectWithoutFlight();
          }}
          checked={!withFlight}
          disabled={!hasNoFlightOption}
          innerRef={!hasFlightOption ? firstInput : undefined}
        >
          Ohne Flug
        </RadioInput>
      </SelectWrap>
      {withFlight ? (
        <>
          <FlightWrap>
            <SelectInput
              label="Flughafen für Anreise"
              options={departureAirportOptions}
              onChange={(option) => {
                selectFlight(option);
                trackGA4FormInteraction('cruie-details', 'click.select.airport-arrival.' + option.name);
              }}
              value={selectedDeparture}
              disabled={hasNoInboundFlightOption}
            />
          </FlightWrap>
          {selectedFlight ? (
            <FlightWrap>
              <SelectInput
                label="Flughafen für Rückreise"
                options={arrivalAirportOptions}
                onChange={(option) => {
                  selectFlight(option);
                  trackGA4FormInteraction('cruie-details', 'click.select.airport-departure.' + option.name);
                }}
                value={selectedArrival}
                disabled={hasNoOutboundFlightOption}
              />
            </FlightWrap>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default FlightSelect;
