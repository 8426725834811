import * as React from 'react';
import styled from 'styled-components/macro';
import { SpinnerSmallBlue } from './Spinner';
import { Download, colors, useUser } from '../lib';
import createTarifInfoPdf from './CruiseDetails/pdfExport';
import { ga4formatString, trackGA4ClickEvent } from '../tealium';
import { FlightSelectState } from './CruiseDetails/FlightSelect';

const Wrapper = styled.div`
  padding-top: 22px;
  width: 250px;
  display: inline-block;
  position: relative;
`;

const StyledButton = styled.button`
  font-size: 18px;
  color: ${colors.blue};

  &:hover {
    & {
      color: ${colors.grayBlue};
    }
  }

  svg {
    position: relative;
    top: 4px;
  }

  span {
    padding-left: 10px;
  }
`;

const Spinner = styled(SpinnerSmallBlue)`
  position: absolute;
  right: 0;
  left: auto;
  top: 27px;
`;

export type PDFButtonProps = {
  label: string;
  tripCode: string;
  detailRequest: any;
  baseData: any;
  cabinCode: string;
  flightState: FlightSelectState;
};

const DetailPDFexport: React.FC<PDFButtonProps> = ({
                                                     label,
                                                     tripCode,
                                                     detailRequest,
                                                     baseData,
                                                     cabinCode,
                                                     flightState: {
                                                       selectedFlight,
                                                     },
                                                   }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const userData = useUser();

  return (
    <Wrapper>
      <StyledButton
        onClick={() => {
          setLoading(true);
          trackGA4ClickEvent('layer.link.' + ga4formatString(label));
          void createTarifInfoPdf(
            () => {
              setLoading(false);
            },
            tripCode,
            userData,
            detailRequest,
            baseData,
            cabinCode,
            selectedFlight ? `${selectedFlight.departureAirport.code}` : '',
          );
        }}
      >
        <Download tabIndex={-1} focusable="false" />
        <span>{label}</span>
      </StyledButton>
      {loading && <Spinner />}
    </Wrapper>
  );
};

export default DetailPDFexport;
