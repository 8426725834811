/**
 let utag_data = {
 cabin_type : '', // Kabinentyp
 cheapest_trip_code : '', // Tripcode der günstigsten Reise
 cheapest_trip_date_end : '', // Reisedatum Enddatum der günstigsten Reise.
 cheapest_trip_date_start : '', // Reisezeitraum Startdatum der günstigsten Reise.
 cheapest_trip_destination_country : '', // Das Land des Zielhafens der günstigsten Reise.
 cheapest_trip_destination_port : '', // Zielhafen der günstigsten Reise.
 cheapest_trip_price_cruise : '', // Günstigster Preis einer Reise ohne Flug.
 conversion_name : '', // Dient zur Bestätigung,dass die Conversion erfolreich absolviert wurde.
 customer_device : '', // Gerät,welches vom Nutzer verwendet wird. Desktop: &quot;&quot;d&quot;&quot;,Mobile: &quot;&quot;m&quot;&quot;
 departure_airport : '', // Airport-Code des Abreiseflughafens
 error_message : '', // Fehlertext der dem User angezeigt wird
 filter_adult_number : '', // In der Suche ausgewählte Anzahl an Erwachsenen
 filter_cabin_type : '', // Gewählter Kabinentyp
 filter_child_number : '', // In der Suche ausgewählte Anzahl an Kindern (2-14)
 filter_date_end : '', // Enddatum des Reisezeitraums aus den Suchparametern.
 filter_date_start : '', // Startdatum des Reisezeitraums aus den Suchparametern.
 filter_early_booking : '', // Checkbox 'Angebote' gewählt
 filter_offer : '', // Checkbox 'Frühbucherermäßigung' gewählt
 filter_region : '', // Liste der Region(en) einer Suchanfrage.
 filter_ship : '', // Liste ausgewählter Schiffe einer Suchanfrage
 filter_special_trip : '', // Auswahl der Spezialreise einer Suchanfrage
 filter_trip_duration : '', // Auswahl der Reisedauer einer Suchanfrage
 gmp_ac_s : '', // Abhängig von gmp_action. Hat nur zwei Werte: ON (bei search),V (bei browse)
 gmp_action : '', // UseCase des Nutzers. Steuert die Tracking-Auswertung. Hat nur zwei Werte: search / browse
 gmp_reg_s : '', // Abhängig von gmp_action. Hat nur zwei Werte: ON (bei search),V (bei browse)
 nav_level1 : '', // Einer der drei Hauptnavigationspunkte
 nav_level2 : '', // Zweite Navigationsebene
 nav_level3 : '', // Dritte Navigationsebene
 nav_level4 : '', // Vierte Navigationsebene
 nav_level5 : '', // Fünfte Navigationsebene
 nav_level6 : '', // Sechste Navigationsebene
 page_title : 'Expert Search', // Titel aus dem CMS der aktuell besuchten Seite.
 page_type : '', // Seitentyp der aktuell besuchten Seite.
 page_url : '', // Pfad der aktuell besuchten Seite.
 party_adult_age : '', // Alter sämtlicher Erwachsener
 party_child_age : '', // Alter sämtlicher Kinder
 region_code : '', // Regionscode aus der Content-Middleware
 route_code : '', // Code der Master-Route mit Prefex &quot;&quot;RG-&quot;&quot;?
 route_recommendations : '', // Empfohlene Routen
 search_trip_ids : '', // TripCodes der ersten vier Suchergebnisse.
 ship : '', // Schiff
 sort_by : '', // Sortierung der Suchseite
 time_date : '', // Timestamp
 timepart_dateISO : '', // Datum des aktuellen Seitenaufrufs
 timepart_dateISOtime : '', // Datum|Uhrzeit des aktuellen Seitenaufrufs
 timepart_day : '', // Wochentag des aktuellen Seitenaufrufs
 timepart_hour : '', // Stunde des aktuellen Seitenaufrufs
 timepart_hour_minute : '', // hh:mm des aktuellen Seitenaufrufs
 timepart_minute : '', // Minute des aktuellen Seitenaufrufs
 trip_code : '', // Tripcode der aktuell besuchten Reisedetailseite.
 trip_date_end : '', // Reiseenddatum der aktuell besuchten Seite
 trip_date_start : '', // Reisestartdatum der aktuell besuchten Seite
 trip_departure_country : '', // Abfahrtsland der aktuell besuchten Seite
 trip_departure_port : '', // Abfahrtshafen der aktuell besuchten Seite
 trip_destination_country : '', // Zielland der aktuell besuchten Seite
 trip_destination_port : '', // Zielhafen der aktuell besuchten Seite
 trip_duration : '', // Reisedauer eines einzelnen Trips.
 trip_name : '', // Name des Trips der aktuell besuchten Reisedetailseite.
 trip_price_cruise : '', // Gesamtpreis ohne Flug
 trip_price_cruise_cents : '', // Gesamtpreis in Cent ohne Flug
 trip_price_cruise_comma : '', // Gesamtpreis mit Komma ohne Flug
 trip_price_cruise_point : '', // Gesamtpreis mit Punkt ohne Flug
 trip_price_total : '', // Gesamtpreis inkl.Flug
 trip_price_total_cents : '', // Gesamtpreis in Cent inkl.Flug
 trip_price_total_comma : '', // Gesamtpreis mit Komma inkl.Flug
 trip_price_total_point : '', // Gesamtpreis mit Punkt inkl.Flug
 trip_region : '', // Region(en) der aktuell besuchten Seite
 trip_region_code : '', // Regionscode der aktuell besuchten Seite
 trip_region_singlestring : '', // Region(en) der aktuell besuchten Reisedetailseite ohne Trennzeichen als einzelner String
 gmp_auto_id : '', // Unique alphanumeric identifying the customer in the browser (generated by market),this would be the auto generated id,which would be used by GMP to link to customer events on retro match
 user_id : '', // Identifies the customer in the source market. This is the id which identifies the customer in the customer feed,which is linked to web event for customer ranking
 page_type_helper : '', // Hilfsvariable,um den Seitentyp zum Filtern in Google Analytics anzuzeigen.
 gmpCustomer : '', // TUIC-ID für Cookies
 tealium_event : '', // Added to connect the Collect events with the Event Specs in UDH
 event_name : '', //
 event_category : '', //
 event_action : '', //
 event_label : '', //
 booking_cabin_deck : '', // Auswahl des Decks
 booking_cabin_type : '', // Auswahl des Kabinentyps
 booking_departure_city : '', // Stadt aus der Anreise erfolgt (z.B. Abflughafen)
 booking_departure_country : '', // Land in dem Abfahrtshafen liegt
 booking_departure_port : '', // Abfahrtshafen der Reise
 booking_destination_city : '', // Stadt in die Rückreise erfolgt (z.B. Rückreise-Flughafen)
 booking_destination_country : '', // Land in dem Zielhafen der Reise liegt
 booking_destination_port : '', // Zielhafen der Reise
 booking_epackage_code : '', // E-Package Code der Reise
 booking_number : '', // Vorgangsnummer
 booking_price_category : '', // Auswahl der Preiskategorie
 booking_price_discount_total : '', // Frühbucherermäßigung (total)
 booking_price_model : '', // Auswahl des Preismodells
 booking_price_total : '', // Gesamtpreis für alle Reisenden
 booking_region : '', // Region der Reise (ausgeschrieben)
 booking_region_code : '', // Regionscode der Reise
 booking_ship_name : '', // Schiff
 booking_transport_type : '', // PSP-Code der Reise
 booking_trip_date_end : '', // Enddatum der Reise
 booking_trip_date_start : '', // Startdatum der Reise
 booking_trip_duration : '', // Dauer der Reise
 booking_trip_name : '', // Route
 insurance_details : '', // Aufruf des Detail-PDFs
 insurance_price_total : '', // Gesamtpreis Versicherung (egal für wieviele Personen)
 insurance_type : '', // Art der Reiseversicherung
 passenger_adult_number : '', // Anzahl der Erwachsenen für ausgewählte Reise
 passenger_children_number : '', // Anzahl der Kinder für ausgewählte Reise
 econda_ecom_event_name : '', //
 conversion_option : '', //
 trip_region_affiliate : '', //
 econda_target_group : '', //
 watchlist_items : '', //
 watchlist_item_count : '', //
 customer_device_long : '', // The longer version of 'customer_device'
 gclid_cookie_string : '', //
 website_section : '', // Should be 'main' or 'ibe', these are two different apps with different behavior
 econda_content : '', // The 'content' parameter for Econda needs to be filled with either the page title (main website) or the navlevel1 (ibe)
 trip_quantity : '', // Will always be 1, set by extension
 ab_variant : '', //
 conversion_success : '', // Only important for PIA, hardcoded to 0
 conversion_quantity : '', // Set to 1, since you can only book a single cruise at a time
 booking_trip_code : '', //
 in_cloudiq_sample : '', //
 booking_region_normalized : '', // normalisierte Regionname aus der Content Middleware (z.b. für PIA)
 iadvize_site_id : '', // We use a different 'site id' for Dev/QA than for Prod
 googleads_conversion_label : '', // We use a different 'conversion-label' for newsletter vs. catalogue-conversion
 bing_conversion_id : '', // conversion-id differs depending on page_type. ( newsletter-success or catalogue-success )
 pia_href : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_referrer : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_pagetype : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_conversion : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_conversion_orderid : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_conversion_total : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_conversion_quantity : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_conversion_success : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_category : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_subcategory1 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_subcategory2 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_subcategory3 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_subcategory4 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_subcategory5 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_productname : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_productid : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_customvar1 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_customvar2 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_customvar3 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_customvar4 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_customvar5 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_customvar6 : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 pia_channel : '', // pia_* vars are used to make the PIA iframe mapping more semantically correct,since the PIA parameters can contain different information depending on the page type
 cheapest_trip_country_regions_port : '', //
 filter_region_affiliate : '', //
 dom_url_copy : '', // A copy of dom.url - dom.url isn't mappable in the UDH, this will be
 booking_ship_code : '', //
 is_b2b : '', //
 utm_campaign : '', //
 utm_content : '', //
 utm_id : '', //
 utm_source : '', //
 utm_term : '', //
 utm_medium : '', //
 currency : '', // hardcoded to Euro
 booking_price_models_number : '', // Angezeigte Preismodelle
 gmpEml : '', // Verschlüsselte Email Adresse
 awin_clickid : '', //
 awin_orderid : '', //
 awin_advertiserid : '', //
 booking_price_models_order : '', // Reihenfolge der angezeigten Preismodelle
 booking_amount_cabins : '', // Anzahl der ausgewählten Kabinen
 exactag_sitegroup : '', //
 exactag_level : '', //
 last_src : '', //
 booking_trip_code_array : '', //
 booking_amount_cabins_array : '', //
 exactag_orderid : '' //
 };
 */
import { Option } from '../types';
import { cruiseListRequest } from '../api';
import { CabinPrice, Cruise } from '../components/CruiseList/Types';
import { useCruiseStore } from '../state/cruise';
import { ibeContentTripInfo } from '../api/ibeApi';
import { useSearchTrackingStore } from '../state/searchTracking';


interface GA4PageViewParameter {
  page_name: string,
  page_platform: string,
  page_country: string,
  page_language: string,
  page_category: string,
  page_url_parameter: string,
  page_login_status: string,
  page_login_variant: string,
  page_test_variant: string,
  page_b2b: string,
}

interface GA4ClickEventParameter extends GA4PageViewParameter {
  event_name: 'click',
  click_label: string
}

interface GA4DownloadParameter extends GA4PageViewParameter {
  event_name: 'download',
  download_specification: string
}

interface GA4FormInteraction extends GA4PageViewParameter {
  event_name: 'form',
  form_name: string,
  form_interaction: string
}

interface GA4CruiseSearchParameter extends GA4PageViewParameter {
  event_name: 'cruise_search' | 'cruise_search_no-results',
  cruise_search_travel_dates: string,
  cruise_search_cabin_occupancy: string,
  cruise_search_results: string,
  cruise_search_rate_filter: string
}

interface GA4ViewItemParameter extends GA4PageViewParameter {
  event_name: 'view_item',
  ec_code: string,
  ec_name: string,
  ec_ship: string,
  ec_region: string,
  item_category: string,
  item_variant: string,
  value: string,
  currency: string,
  ec_quantity: '1',
  ec_range: string,
  ec_passengers: string,
  ec_nights: string,
  ec_cabin_type: string,
  ec_start_date: string,
  ec_end_date: string,
  ec_gap: string,
}

declare global {
  interface Window {
    utag: {
      // https://docs.tealium.com/platforms/javascript/api/tracking-functions/
      // Tracks non-page views, page interactions, and other dynamic page events
      link: (
        data: {
          event_category: string;
          event_action: string;
          event_label: string;
        } | GA4ClickEventParameter | GA4CruiseSearchParameter | GA4ViewItemParameter | GA4DownloadParameter | GA4FormInteraction,
        callback?: () => void,
        UIDs?: any[],
      ) => void;
      // Tracks page views, virtual page views, and Ajax page flows
      view: (
        data?: {
          page_title?: string
        } | GA4PageViewParameter,
        callback?: () => void,
        UIDs?: any[],
      ) => void;
      // https://docs.tealium.com/platforms/javascript/api/gdpr-functions/
      gdpr: any
    };
  }
}

const trackingActive = (): boolean => {
  if (window.utag && window.utag.gdpr) {
    return window.utag.gdpr.getConsentState() === 1;
  }
  return false;
};

export const TITLE = 'Expert Search';
export const CATEGORY_DEFAULT = 'Expertsearch';
export const CATEGORY_ERROR = 'Expertsearch_Fehler';


export const trackingValueList = (options: Option[]) =>
  options.map((o) => o.name).join(', ') || 'Alle';



export const trackGA4ClickEvent = (click_label: string) => {
  if (!trackingActive() || !click_label) {
    return;
  }

  const track: GA4ClickEventParameter = {
    ...ga4DefaultPageViewParameter(),
    click_label: ga4formatString(click_label),
    event_name: 'click',
  };

  console.log('click', track);
  window.utag.link(track);
};

export const trackGA4ViewItemEvent = (cruise: Cruise, tripInfo: ibeContentTripInfo, value: CabinPrice) => {
  if (!trackingActive()) {
    return;
  }

  const { search } = useSearchTrackingStore.getState();

  let period;
  switch (search.period.duration) {
    case 'A':
      period = '1-7-naechte';
      break;
    case 'B':
      period = '8-13-naechte';
      break;
    case 'C':
      period = '14-naechte';
      break;
    case 'E':
      period = 'exakt';
      break;
    default:
      period = '0';
      break;
  }

  const track: GA4ViewItemParameter = {
    ...ga4DefaultPageViewParameter(),
    event_name: 'view_item',
    page_name: ga4formatString('expertsearch_meinschiff_com|detail|' + cruise.name),
    ec_code: cruise.journeyIdentifier,
    ec_name: ga4formatString(cruise.name),
    ec_ship: cruise.shipCode ? ('mein-schiff-' + cruise.shipCode.charAt(cruise.shipCode.length - 1)) : '',
    value: ga4FormatPrice(String(value.amount)),
    item_variant: ga4formatDate(tripInfo.dateFrom) + '-' + ga4formatDate(tripInfo.dateTo),
    item_category: ga4formatString(tripInfo.customvar6),
    ec_region: ga4formatString(tripInfo.region[0].regionName),
    ec_quantity: '1',
    currency: 'EUR',
    ec_range: period,
    ec_passengers: search ? (`${search.passenger.adults}.${search.passenger.children}`) : '',
    ec_nights: String(cruise.nights),
    ec_cabin_type: cruise.cabins.length ? ga4formatString(cruise.cabins[0].name) : '',
    ec_start_date: ga4formatDate(tripInfo.dateFrom),
    ec_end_date: ga4formatDate(tripInfo.dateTo),
    ec_gap: calculateDaysBetween(new Date(), cruise.startDate),
  };


  console.log('view item', track);

  window.utag.link(track);
};

export const trackGA4SearchEvent = (results: number, searchParams: cruiseListRequest, tariffFilter: string[]) => {
  if (!trackingActive()) {
    return;
  }

  let cruise_search_travel_dates = searchParams.period.start && searchParams.period.end ? `period=${ga4formatDate(searchParams.period.start.date)}-${ga4formatDate(searchParams.period.end.date)}` : '';

  if (searchParams.period.start.date) {
    cruise_search_travel_dates += '.start_date=1';
  } else {
    cruise_search_travel_dates += '.start_date=0';
  }

  if (searchParams.period.end && searchParams.period.end.date) {
    cruise_search_travel_dates += '.end_date=1';
  } else {
    cruise_search_travel_dates += '.end_date=0';
  }

  switch (searchParams.period.duration) {
    case 'A':
      cruise_search_travel_dates += '.duration=1-7-naechte';
      break;
    case 'B':
      cruise_search_travel_dates += '.duration=8-13-naechte';
      break;
    case 'C':
      cruise_search_travel_dates += '.duration=14-naechte';
      break;
    case 'E':
      cruise_search_travel_dates += '.duration=exakt';
      break;
    default:
      cruise_search_travel_dates += '.duration=0';
      break;
  }

  if (searchParams.geoLocation && searchParams.geoLocation.regions && searchParams.geoLocation.regions.length) {
    cruise_search_travel_dates += '.region=' + searchParams.geoLocation.regions.join('|');
  } else {
    cruise_search_travel_dates += '.region=0';
  }

  if (searchParams.cruise && searchParams.cruise.shipCodes && searchParams.cruise.shipCodes.length) {
    cruise_search_travel_dates += '.ship=' + searchParams.cruise.shipCodes.map((shipCode) => 'mein-schiff-' + shipCode.charAt(shipCode.length - 1)).join('|');
  } else {
    cruise_search_travel_dates += '.ship=0';
  }

  let cruise_search_cabin_occupancy = 'cabin=' + (searchParams.cruise && searchParams.cruise.cabinTypes && searchParams.cruise.cabinTypes.length ? searchParams.cruise.cabinTypes.join('|') : '0');

  cruise_search_cabin_occupancy += '.adults=' + searchParams.passenger.adults;
  cruise_search_cabin_occupancy += '.children=' + searchParams.passenger.children;


  const track: GA4CruiseSearchParameter = {
    ...ga4DefaultPageViewParameter(),
    event_name: results === 0 ? 'cruise_search_no-results' : 'cruise_search',
    cruise_search_travel_dates,
    cruise_search_cabin_occupancy,
    cruise_search_results: String(results),
    cruise_search_rate_filter: tariffFilter && tariffFilter.length ? tariffFilter.map((tariff) => tariff.toLowerCase()).join('|') : '0',
  };

  console.log('search', track);

  window.utag.link(track);
};

export const ga4DefaultPageViewParameter = (): GA4PageViewParameter => {
  const queryIndex = window.location.href.indexOf('?');
  const page_url_parameter = typeof window !== 'undefined' && queryIndex ? window.location.href.substring(queryIndex + 1) : '';
  const { cruise } = useCruiseStore.getState();
  return ({
    page_platform: 'expertsearch',
    page_country: 'de',
    page_language: 'de',
    page_category: 'search',
    page_test_variant: 'original',
    page_url_parameter,
    page_name: cruise ? ga4formatString('expertsearch_meinschiff_com|detail|' + cruise.name) : (window.location.href.includes('cruises') ? 'expertsearch_meinschiff_com|suchergebnisseite' : 'expertsearch_meinschiff_com|startseite'),
    page_login_status: 'true',
    page_login_variant: 'infonet',
    page_b2b: 'true',
  });
};

//new GA4 page view
export const trackPageView = (page_name: string, cruise_name?: string) => {
  if (!trackingActive()) {
    return;
  }
  const defaultParams = ga4DefaultPageViewParameter();

  const track: GA4PageViewParameter = {
    ...defaultParams,
    page_name: cruise_name ? ga4formatString('expertsearch_meinschiff_com|detail|' + cruise_name) : page_name,
  };

  console.log('page view', track);

  window.utag.view(track);
};

export const trackGA4Download = (download_specification: string) => {
  if (!trackingActive()) {
    return;
  }

  const defaultParams = ga4DefaultPageViewParameter();

  const track: GA4DownloadParameter = {
    ...defaultParams,
    event_name: 'download',
    download_specification,
  };

  console.log('download', track);

  window.utag.link(track);
};

export const trackGA4FormInteraction = (form_name: string, form_interaction: string) => {
  if (!trackingActive()) {
    return;
  }

  const defaultParams = ga4DefaultPageViewParameter();

  const track: GA4FormInteraction = {
    ...defaultParams,
    event_name: 'form',
    form_name: ga4formatString('expertsearch.' + form_name),
    form_interaction: ga4formatString(form_interaction),
  };

  console.log('form interaction', track);

  window.utag.link(track);
};

export const ga4formatString = (value: string) => {
  let sanitized = value
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss');

  // Convert all to lower case
  sanitized = sanitized.toLowerCase();

  // Replace spaces with dashes
  sanitized = sanitized.replace(/\s+/g, '-');

  // Replace special characters, preserving allowed ones (., -, _, |)
  sanitized = sanitized.replace(/[^a-z0-9-_.|&]/g, '-');

  // Replace multiple dashes with a single dash
  sanitized = sanitized.replace(/-+/g, '-');

  // Remove dashes at the start or end of the string
  sanitized = sanitized.replace(/^-|-$/g, '');

  return sanitized;
};

export const ga4FormatPrice = (value: string) => {
  return Number.parseFloat(value).toFixed(2);
};

export const ga4formatDate = (value: unknown) => {
  let newValue = String(value);
  newValue = newValue.split('-').join('');
  return newValue;
};

export const ga4formatDateObject = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-based index
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}${month}${day}`;
};


export const calculateDaysBetween = (date1: Date, date2: Date): string => {
  // Calculate the difference in time (in milliseconds)
  const differenceInTime = date2.getTime() - date1.getTime();

  // Convert the difference from milliseconds to days
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return String(Math.floor(Math.abs(differenceInDays)));
};
